@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use 'variables' as var;
@include spb.set-typography;

// Remove annoying 5px margin
body, html {
  margin: 0;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: var.$spb-max-width;
  width: 100%;
}

.spb-error {
  background-color: var.$spb-red;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

@media screen and (width >= 400px) {
  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 33%;
  }
}

@media screen and (max-width: var.$spb-max-width) and (min-width: var.$spb-small) {
  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 33%;
  }
}

@media screen and (width <= 1215px) and (width >= 400px) {
  .spb-plain-content > * {
    padding-left: 65px;
    padding-right: 20%;
  }
}

@media screen and (width <= 400px) {
  .spb-plain-content > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/**
  Checkbox labels do not line break and have to be here ... 2019-08-08 /Daniel
 */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin: 4px 8px auto 0 !important;
}

h1,
h2,
h3 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

h4 {
  margin-bottom: 7px !important;
  margin-top: 20px !important;
}

.buttons {
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}